import { createStore as reduxCreateStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"

const reducer = (state, action) => {
  console.log("[[ACTION]]")
  console.log(action)
  console.log("[[ACTION.TYPE]]")
  console.log(action.type)
  console.log("[[STATE]]")
  console.log(state)

  if (action.type === "USER_LOGOUT") {
    console.log("<<<<<<initialState>>>>>>>>>")
    console.log(initialState)
    return initialState
  }

  if (action.type === "USER_LOGIN") {
    console.log("createStore - USER_LOGIN - action.data")
    console.log(action.data)
    return {
      ...state,
      isLoading: false,
      userlogin: action.data,
      usersub: action.data.sub,
      usererror: {},
    }
  }

  if (action.type === "USER_LOGIN_ERROR") {
    return { ...state, isLoading: false, usererror: action.data }
  }
  if (action.type === "USER_PROFILE_DETAIL_REPLACE") {
    console.log("createStore - USER_PROFILE_DETAIL_REPLACE - action.data")
    console.log(action.data)
    return {
      ...state,
      isLoading: false,
      isProfileUpdateComplete: true,
      userdetail: action.data,
      userdetailemail: {
        email: action.data.email,
        mobile: action.data.mobile,
        displayname: action.data.displayname,
        verifycode: "",
        error: "",
      },
    }
  }
  if (action.type === "USER_PROFILE_DETAIL_COMPLETE") {
    return {
      ...state,
      isProfileUpdateComplete: false,
    }
  }
  if (action.type === "COMPANY_DETAIL_REPLACE") {
    console.log("createStore - COMPANY_DETAIL_REPLACE - action.data")
    console.log(action.data)
    // if (action.data.user) {
    //   delete action.data.user.email
    // }
    return {
      ...state,
      isLoading: false,
      company: action.data.company,
      userdetail: action.data.user,
      userdetailemail: {
        email: action.data.user.email,
        mobile:
          action.data.user && action.data.user.mobile
            ? action.data.user.mobile
            : "",
        displayname: action.data.user.displayname,
        verifycode: "",
        error: "",
      },
      usererror: {},
    }
  }

  if (action.type === "SET_STATE") {
    return { ...state, [action.data.prop]: action.data.val }
  }
  if (action.type === "LOADING_START") {
    console.log("load 'em up")
    return { ...state, isLoading: true }
  }
  if (action.type === "LOADING_END") {
    console.log("load 'em up")
    return { ...state, isLoading: false }
  }
  if (action.type === "LOADING_SUBMISSION_START") {
    console.log("load 'em up")
    return { ...state, loadingSubmission: true }
  }
  if (action.type === "LOADING_MINI_START") {
    console.log("load 'em up")
    return { ...state, isLoadingMini: { [action.data.sessionid]: true } }
  }
  if (action.type === "LOADING_MODERATESUBMIT_START") {
    console.log("load 'em up")
    return { ...state, isLoadingModerate: { [action.data.sessionid]: true } }
  }
  if (action.type === "CAMPAIGNS_REPLACE") {
    console.log("campaigns-replace")
    return { ...state, isLoading: false, campaign: action.data }
  }
  if (action.type === "AGREEMENT_REPLACE") {
    console.log("agreement-replace")
    return { ...state, isLoading: false, agreements: action.data }
  }
  if (action.type === "CAMPAIGNS_TOACTIVATE_REPLACE") {
    console.log("campaigns-toactivate-replace")
    return { ...state, isLoading: false, campaignactivate: action.data }
  }
  if (action.type === "CAMPAIGN_ACTIVATE_REPLACE") {
    console.log("CAMPAIGN_ACTIVATE_REPLACE")
    return {
      ...state,
      loadingSubmission: false,
      submitted: true,
      campaignactivated: action.data,
    }
  }
  if (action.type === "VIDEOS_CREATED_GROUPED_REPLACE") {
    console.log("videos-GROUPED-replace")
    return { ...state, isLoading: false, videosgrouped: action.data }
  }
  if (action.type === "VIDEOS_USERS_REPLACE") {
    console.log("videos-RAW-replace")
    return { ...state, isLoading: false, users: action.data }
  }
  if (action.type === "SOCIAL_GROUPED_REPLACE") {
    console.log("SOCIAL-GROUPED-replace")
    return { ...state, isLoading: false, social: action.data }
  }

  if (action.type === "REVIEWS_REPLACE") {
    console.log("REVIEWS_REPLACE-replace")
    console.log({ ...state, isLoading: false, reviews: action.data })
    return { ...state, isLoading: false, reviews: action.data }
  }
  if (action.type === "VIDEO_TAGS_UPDATE") {
    let tags = []
    if (action.data && action.data.tags) {
      tags = action.data.tags
    }
    const newReviews = state.reviews[action.data.templateid].map((item) => {
      console.log("***item")
      console.log(item)
      if (item.id === action.data.id) {
        return { ...item, tags: tags }
      }
      return item
    })

    console.log(">>pre return")
    console.log(state)
    console.log(">>newReviews")
    console.log(newReviews)
    return {
      ...state,
      isLoadingMini: { [action.data.sessionid]: false },
      reviews: { [action.data.templateid]: newReviews },
    }
  }

  if (action.type === "VIDEOS_TOMODERATE_REPLACE") {
    console.log("VIDEOS_TOMODERATE_REPLACE")
    return { ...state, isLoading: false, moderatevideos: action.data }
  }
  if (action.type === "VIDEOS_AFTERMODERATE_REPLACE") {
    console.log("VIDEOS_AFTERMODERATE_REPLACE")
    return {
      ...state,
      isLoadingModerate: { [action.data.sessionid]: false },
      moderatevideos: state.moderatevideos.filter(
        (item) => item.id !== action.data.sessionid
      ),
    }
  }

  if (action.type === "MAILMERGE_SETHTML_REPLACE") {
    console.log("MAILMERGE_SETHTML_REPLACE")
    return { ...state, isLoading: false, mailmerge: action.data }
  }
  if (action.type === "MAILMERGE_SETRECIPIENTS_REPLACE") {
    console.log("MAILMERGE_SETHTML_REPLACE")
    return { ...state, isLoading: false, mailmergeusers: action.data }
  }
  if (action.type === "MAILMERGE_AUX_REPLACE") {
    console.log("MAILMERGE_AUX_REPLACE")
    return {
      ...state,
      isLoading: false,
      mailmergeaux: { ...state.mailmergeaux, ...action.data },
    }
  }
  if (action.type === "USERDATA_REPLACE") {
    console.log("USERDATA_REPLACE")
    return { ...state, isLoading: false, userdata: action.data }
  }
  if (action.type === "SENDMAIL_REPLACE") {
    console.log("SENDMAIL_REPLACE")
    return {
      ...state,
      isLoading: false,
      sendmail: [action.data, ...state.sendmail],
    }
  }
  if (action.type === "SENDMAIL_ERROR") {
    console.log("SENDMAIL_ERROR")
    return { ...state, isLoading: true, sendmailerror: action.data }
  }
  if (action.type === "MODIFYCAMPAIGN_REPLACE") {
    console.log("MODIFYCAMPAIGN_REPLACE")
    return {
      ...state,
      isLoading: false,
      modcampaign: { ...state.modcampaign, ...action.data },
    }
  }

  if (action.type === "MODIFYCAMPAIGN_LIST") {
    console.log("MODIFYCAMPAIGN_LIST")
    return {
      ...state,
      isLoading: false,
      modcampaignlist: action.data,
    }
  }
  if (action.type === "MODIFYCAMPAIGN_HISTORY") {
    console.log("MODIFYCAMPAIGN_HISTORY")
    return {
      ...state,
      isLoading: false,
      modcampaignhistory: [action.data, ...state.modcampaignhistory],
    }
  }

  if (action.type === "MODIFYCAMPAIGN_ERROR") {
    console.log("MODIFYCAMPAIGN_ERROR")
    return { ...state, isLoading: true, modcampaignerror: action.data }
  }

  if (action.type === "MODIFYCAMPAIGN_ENGAGE_REPLACE") {
    console.log("MODIFYCAMPAIGN_ENGAGE_REPLACE")
    return { ...state, isLoading: false, modcampaignengage: action.data }
  }

  if (action.type === "COLLECTOR_REPLACE") {
    console.log("COLLECTOR_REPLACE")
    return {
      ...state,
      isLoading: false,
      contactcollector: [action.data, ...state.contactcollector],
    }
  }

  if (action.type === "IMAGECOMPOSITE_REPLACE") {
    console.log("IMAGECOMPOSITE_REPLACE")
    return {
      ...state,
      isLoading: false,
      imagecomposite: action.data && action.data.image ? action.data.image : "",
    }
  }

  console.log("pre state>>>")
  return state
}

const initialState = {
  isLoading: false,
  filterSort: "asc",
  filterStar: false,
  sendmail: [],
  contactcollector: [],
  mailmergeaux: {
    dryRun: true,
    gettingStarted: false,
    overrideUnsub: false,
    dataMarker: 1,
    emailTextLoaded: false,
    emailTextFileName: "",
    recipientsLoaded: false,
    recipientsFileName: "",
    emailBcc: "",
  },
  modcampaign: {
    tabState: "search",
    campaignMode: "add",
    campaignName: "",
    campaignId: "",
    campaignSearch: "",
  },
  modcampaignlist: [],
  modcampaignhistory: [],
  imagecomposite: "",
}

const createStore = () =>
  reduxCreateStore(reducer, initialState, applyMiddleware(thunk))

export default createStore
